import React from 'react';
import { Link } from 'react-router-dom';

const BlogSidebar = () => {
  return (
    <div className="col-lg-4">
      <div className="blogsidebar_wrapper">

        <div className="widget repost_widget">
          <h4 className="widget_title">Recent Post</h4>
          <div className="widget_rp">
            <ul>
              <li>
                <div className="rp_thumb"><a href="# ">
                  <img src="/images/blogs/ml-tech-thumbnail.png" alt="" className="img-fluid" /></a></div>
                <div className="rp_data">
                  <Link to="/blog/the-6-most-important-technologies-in-machine-learning" className="rp_heading">
                    The 6 Most Important Technologies in ML
                    </Link>
                  <div><a href="# " className="rp_date">january 28th, 2020</a></div>
                </div>
              </li>
              <li>
                <div className="rp_thumb"><a href="# ">
                  <img src="/images/blogs/web-development-thumbnail.jpg" alt="" className="img-fluid" /></a></div>
                <div className="rp_data">
                  <Link to="/blog/technology-stack-for-web-application-development-in-2020" className="rp_heading">
                    Technology stack for web application development
                    </Link>
                  <div><a href="# " className="rp_date">Febuary 10, 2020</a></div>
                </div>
              </li>
              <li>
                <div className="rp_thumb"><a href="# ">
                  <img src="/images/blogs/e-health-thumbnail.jpg" alt="" className="img-fluid" /></a></div>
                <div className="rp_data">
                  <Link to="/blog/5-ways-technology-is-improving-health" className="rp_heading">5 Ways Technology is Improving Health</Link>
                  <div><a href="# " className="rp_date">April 28th, 2020</a></div>
                </div>
              </li>

            </ul>
          </div>
        </div>

        <div className="widget tag_widget">
          <h4 className="widget_title">Tag</h4>
          <ul>
            <li><a href="# " className="comment_reply">Technology</a></li>
            <li><a href="# " className="comment_reply">Web Development</a></li>
            <li><a href="# " className="comment_reply">Machine Learning</a></li>
            <li><a href="# " className="comment_reply">Health</a></li>
            <li><a href="# " className="comment_reply">React</a></li>
            <li><a href="# " className="comment_reply">Django</a></li>
            <li><a href="# " className="comment_reply">Frontend</a></li>
            <li><a href="# " className="comment_reply">Backend</a></li>
          </ul>
        </div>
      </div>
    </div>

  );
}

export default BlogSidebar;
