import React, { useState } from 'react';
import BlogSidebar from './BlogSidebar';
import PageLoader from '../PageLoader';

const TechnologyImprovingHealth = () => {
  document.getElementById("top_banner").classList.add("d-none");
  const [imageLoading, setImageLoading] = useState(true);

  const handleImageLoaded = () => {
    setImageLoading(false);
  }

  const loading = imageLoading ? <PageLoader /> : "";

  return (
    <div className="port_singleblog_wrapper prt_toppadder80 prt_bottompadder80 ">
      {loading}
      <div className={`container ${imageLoading ? 'invisible' : ''}`}>
        <div className="row">
          <div className="col-lg-8">
            <div className="blog_wrapper">
              <div className="blog_data">
                <div className="blog_thumb">
                  <img id="blogImage" src="/images/blogs/e-health.jpg" onLoad={handleImageLoaded} alt="" className="img-fluid" />
                </div>
                <div className="blog_content">
                  <div className="blog_postinfo pb-3">
                    <ul>
                      <li><a href=" #"> <i className="fas fa-calendar-alt"></i> April 28th, 2020</a></li>
                      <li><a href=" #"> <i className="fas fa-user-edit"></i> by Neo</a></li>
                    </ul>
                  </div>
                  <h4 className="blog_heading mb-2">5 Ways Technology is Improving Health</h4>

                  <p>It could be argued that of all the ways technology has improved the lives of humans across the globe, none are as important as the technological advances in medicine. From the invention of X-ray machines to advances in surgical practices, technology has made us healthier and increased life expectancies[1]. As we move further into the 21st century, we continue to develop technologies that cure illnesses and improve our quality of life. Health informatics is heavily integrated with technology and graduates with a Master of Science in Health Informatics degree can help to push the boundaries of healthcare throughout their careers.</p>
                  <h2>Electronic Health Records (EHRs)</h2>
                  <p>As any student of health informatics knows, the movement toward electronic health records is a huge evolution in the medical field, and one that can facilitate countless advances in medical diagnostics and treatment.</p>
                  <p>In the past, hospitals had disparate information systems that were clumsy in their ability to share and transfer patient records. With the advent of EHRs, many systems have been connected, allowing for faster information transfers and more integrated and efficient care.</p>
                  <h2>Telemedicine / Telehealth</h2>
                  <p>Telemedicine refers to methods of advancing healthcare based on telecommunications technologies. There are several reasons telehealth is becoming more popular and successful. First, in rural areas where access to hospitals and other health related services are lacking, patients can use their computer to meet virtually with a doctor. Secondly, the cost benefits associated with telehealth are substantial. According to an Alliance for Connected Care study, telehealth services can save up to $100 per doctor visit [2].</p>
                  <p>Another study published in CHEST Journal shows patients in an ICU equipped with telehealth services were discharged 20% faster and had a 26% lower mortality rate than traditional ICUs[1].</p>
                  <h2>Remote Monitoring Tools</h2>
                  <p>When patients monitor their health at home, they can save money and reduce unnecessary visits to the doctor&rsquo;s office. At the end of 2012, almost 3 million patients worldwide were using home health monitoring systems[1].</p>
                  <p>Pacemakers for patients with heart disease can automatically send data to remote health centers. Great for patients with chronic illnesses, these tools can allow systems to monitor a patient&rsquo;s health from a distance.</p>
                  <h2>Wearable Technology</h2>
                  <p>The wearable medical device market is growing quickly. These devices collect data, which helps doctors and patients alike monitor and assess the health of the wearer.</p>
                  <p>In addition to devices that alert authorities about serious medical issues, there are very popular wearables like wristbands and watches that are allowing users to take an active role in their health. According to Huffington Post, by 2018 an expected 130 million wearable devices will have been shipped to consumers[6].</p>
                  <h2>Genome Sequencing</h2>
                  <p>Some have called personal genomics the future of healthcare. Personal genomics refers to the sequencing and analysis of one person&rsquo;s genome and then giving that person his or her genomic information[5].</p>
                  <p>Sequencing the human genome has been one of the greatest advancements in medical technology of the last 40 years. Dr. Francis Collins, director of the National Institutes of Health, says in Modern Healthcare, &ldquo;For all of human history, we have labored without understanding our instruction book, and the human genome provided that. It&rsquo;s like crossing a bridge[4].&rdquo;</p>
                  <p>The technological advances in the medical field have allowed for many life saving procedures to become commonplace. Just the thought of virtually visiting a doctor from hundreds or thousands of miles away would have been difficult to believe only a few decades ago. From remote monitoring tools and wearable medical technology to the sequencing of genomes, technology is improving health at a rapid pace. To become a part of the convergence of medicine and technology, consider getting a&nbsp;<a href="http://healthinformatics.uic.edu/health-informatics-degrees/" data-croloc="content" data-crolinktype="internal" data-crolinkstyle="link">Master of Science in Health Informatics</a>&nbsp;from UIC.</p>

                  <div className="blog_shareinfo">
                    <div className="blog_tag">
                      <a href=" #"> <i className="fas fa-tags"></i> education, Software, portfolio</a>
                    </div>
                    <div className="blog_social">
                      <ul className="social_list">
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-facebook-f nav_fb"></i></span>
                            <span className="second_icon"><i className="fab fa-facebook-f nav_fb"></i></span>
                          </a>
                        </li>
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-linkedin-in nav_in"></i></span>
                            <span className="second_icon"><i className="fab fa-linkedin-in nav_in"></i></span>
                          </a>
                        </li>
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-whatsapp nav_whats"></i></span>
                            <span className="second_icon"><i className="fab fa-whatsapp nav_whats"></i></span>
                          </a>
                        </li>
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-twitter nav_twit"></i></span>
                            <span className="second_icon"><i className="fab fa-twitter nav_twit"></i></span>
                          </a>
                        </li>
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-instagram nav_insta"></i></span>
                            <span className="second_icon"><i className="fab fa-instagram nav_insta"></i></span>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <BlogSidebar />
        </div>
      </div>
    </div>
  );
}

export default TechnologyImprovingHealth;
