import React from 'react';

const MyProjects = () => {
  return (
    <div className="port_projects_setions prt_bottompadder50 prt_toppadder80 page_scroll" data-scroll="2" id="project_sec">
      <div className="projects_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="port_heading_wrapper text-center prt_bottompadder40">
                <div className="port_sub_heading_wrapper">
                  <h2 className="port_sub_heading">Quality Work</h2>
                </div>
                <h1 className="port_heading">My Projects</h1>
              </div>
            </div>

            <div className="port_project_gallery text-center">

              <div className="gallery_container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="grid-item design w-100">
                      <a href="https://django-react-ecommerce.vercel.app/" target="_blank" rel="noopener noreferrer" className="view">
                        <div className="grid_img">
                          <img src="/images/dr-ecom.jpg" alt="project-img" />
                        </div>
                        <div className="grid-content">
                          <h3>Django React Ecommerce</h3>
                          <span>Ecommerce site developed in Django and React</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="grid-item design w-100">
                      <a href="https://yournxtstore.com" target="_blank" rel="noopener noreferrer" className="view">
                        <div className="grid_img">
                          <img src="/images/yournxtstore.jpg" alt="project-img" />
                        </div>
                        <div className="grid-content">
                          <h3>Your NXT Store</h3>
                          <span>A full fledge ecom site currently delivering products to customers</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="grid-item design w-100">
                      <a href="https://dashboard.edor.in" target="_blank" rel="noopener noreferrer" className="view">
                        <div className="grid_img">
                          <img src="/images/edor.jpg" alt="project-img" />
                        </div>
                        <div className="grid-content">
                          <h3>EDOR Dashboard</h3>
                          <span>A dashboard app for students to consult career choice with experts.
                            Demo username: basanta, password: 11111111
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProjects;
