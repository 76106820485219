import React from 'react';

import AboutSection from '../components/AboutSection';
import Education from '../components/Education';
import ExperienceSection from '../components/ExperienceSection';
import SkillSet from '../components/SkillSet';
import MyProjects from '../components/MyProjects';
import BlogSection from '../components/BlogSection';

const Home = () => {
  return (
    <>
      <AboutSection />
      <Education />
      <ExperienceSection />
      <SkillSet />
      <MyProjects />
      <BlogSection />

    </>
  )
}

export default Home;
