import React, { useState } from 'react';

const AboutSection = () => {
	const [openDetails, setOpenDetails] = useState(false);

	const handleOpenDetails = () => {
		setOpenDetails(!openDetails);
		console.log('Clicked')
	}
	return (
		<div className="port_about_setions prt_toppadder80 page_scroll" data-scroll="0" id="about_sec">
			<div className="selfintro_section">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 col-lg-12 col-md-12">
							<div className={`about_leftsection ${openDetails ? 'open_details' : ''}`}>
								<div className="img_warapper">
									<img className="img-fluid" src="/images/selfintro.jpg" alt="About" title="more information click + icon" />
									<div className="icon" title="more information click + icon" onClick={handleOpenDetails}>
										<div className="iconbox">
											<i className="fa fa-plus icon_plus" aria-hidden="true" ></i>
											<i className="fa fa-minus icon_minus" aria-hidden="true" ></i>
										</div>
									</div>
								</div>
								<div className="left_deatils">
									<div className="personal_details">
										<h1 className="ps_name">Ningthem Yumnam</h1>
										<p className="ps_designation">Full Stack Developer</p>
										<ul className="info-list">
											{/* <li><span className="title">Age</span><span className="value">27</span></li> */}

											<li><span className="title">Address</span><span className="value">Bengaluru, India</span></li>
											<li><span className="title">E-mail</span><span className="value"><a href="mailto:email@example.com">neoyumnam@gmail.com</a></span></li>
											{/* <li><span className="title">Residence</span><span className="value">USA</span></li> */}
											{/* <li><span className="title">Phone</span><span className="value">+0123 789 123 456 </span></li> */}
											{/* <li><span className="title">Skype</span><span className="value">Caseyj.mullins</span></li> */}
											<li>
												<span className="title">FrontEnd</span><span className="value available">
													React, Redux, Axios, React-Redux, Lazy Loading, HTML, CSS
												</span>
											</li>
											<li>
												<span className="title">BackEnd</span><span className="value available">
													Python, Django, Django REST Framework, FastAPI, Distributed systems
												</span>
											</li>
											<li>
												<span className="title">DevOps</span><span className="value available">
													Docker, Kubernetes, AWS EKS
												</span>
											</li>
										</ul>

										<ul className="social-links">
											<li>
												<a className="tip social-button" href="https://www.facebook.com/Neoyumnam/" target="_blank" rel="noopener noreferrer">
													<i className="fab fa-facebook-f"></i>
												</a>
											</li>
											<li><a className="tip social-button" href="https://www.linkedin.com/in/ningthem" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
											<li><a className="tip social-button" href="https://twitter.com/Neo_Yumnam" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
											<li><a className="tip social-button" href="https://www.instagram.com/NeoYumnam" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
										</ul>

									</div>
								</div>

							</div>
						</div>
						<div className="col-xl-7 col-lg-12 col-md-12">
							<div className="right_section">
								<div className="port_heading_wrapper">
									<div className="port_sub_heading_wrapper">
										<h2 className="port_sub_heading" >About Me </h2>
									</div>
								</div>
								<h2 className="about_tophead">I'm In The Development Industry With 7+ Years Of Experience.</h2>
								<p className="about_details mb-2">
									I am a developer who loves to design, build, solve practical problems, and bring it to life. It may sound dramatic but my love for coding started when I first saw the movie "The Matrix", in 2002. I didn't understand what was happening, but seeing them type lines of code into a system made me so curious as a kid and wanted to learn so much.
								</p>
								<p className="about_details">
									Through my years as a developer, what I noticed about myself is, I enjoy the sense of accomplishment when I design and build something and make it work. Also, we have to learn and understand the very small simple basics to build something big and amazing. Hence, to this day, I continue to learn new skills by taking up new courses.
								</p>
								<div className="signature_box">
									<div className="name">
										<h2>Ningthem Yumnam</h2>
										<p>Full Stack Developer/Space Nerd</p>
									</div>

									<div className="signature">
										<img src="/images/signature.png" className="invert" style={{ width: "210px" }} alt="" />
									</div>
								</div>
								<div className="anout_section_btn">
									<a href="/files/Resume.pdf" target="_blank" rel="noopener noreferrer" className="portfolio_btn btn_yellow">
										<span className="first_text">Download CV</span>
										<span className="second_text">Download</span>
									</a>
									<a href="https://www.linkedin.com/in/ningthem" target="_blank" rel="noopener noreferrer" className="portfolio_btn btn_red" id="redirect_contact">
										<span className="first_text">Meet Me</span>
										<span className="second_text">GoTo LinkedIn</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutSection;
