import React from 'react';
import { Link } from 'react-router-dom';

const BlogSection = () => {
  return (
    <div className="port_blog_setions prt_toppadder80 prt_bottompadder50" data-scroll="3" id="blogs_sec">
      <div className="blog_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="port_heading_wrapper text-center prt_bottompadder40">
                <div className="port_sub_heading_wrapper">
                  <h2 className="port_sub_heading">My Blog</h2>
                </div>
                <h1 className="port_heading">Recent News</h1>
              </div>
            </div>

            <div className="col-md-12 col-lg-4">
              <div className="port_blog_mainbox vertical_content">
                <div className="port_blog_imgbox">
                  <img src="/images/blogs/chatgpt-thumbnail-1.png" alt="" className="img-fluid" />
                </div>


                <div className="port_blog_contentbox">
                  <span className="date_span">August 20th, 2023</span>
                  <h4 className="blog_heading">
                    <Link to="/blog/applications-of-chatgpt">
                      Applications of ChatGPT and its signigicance
                    </Link>
                  </h4>
                  <div className="blog_info">
                    <ul>
                      <li><i className="fas fa-user-edit"></i><a href="# ">Authored By ChatGPT</a> </li>
                    </ul>
                  </div>
                  <p>ChatGPT excels in customer support automation, enhancing content creation processes, and providing interactive educational assistance.</p>
                  <div className="blog_readmore">
                    <Link to="/blog/applications-of-chatgpt" className="readmore_btn">Read More <i className="fas fa-long-arrow-alt-right"></i></Link>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-8 col-md-12 ">
              <div className="port_blog_mainbox left_content">
                <div className="row no-gutters">
                  <div className="col-md-6 order-md-1 order-2">
                    <div className="port_blog_contentbox">
                      <span className="date_span">February 10, 2020</span>
                      <h4 className="blog_heading">
                        <Link to="/blog/technology-stack-for-web-application-development-in-2020">Technology stack for web application development</Link>
                      </h4>
                      <div className="blog_info">
                        <ul>
                          <li><i className="fas fa-user-edit"></i><a href="# ">By Neo</a> </li>
                        </ul>
                      </div>
                      <p>What technologies are still on the top in 2020? Let’s see what tools are the best to work with:</p>
                      <div className="blog_readmore">
                        <Link to="/blog/technology-stack-for-web-application-development-in-2020" className="readmore_btn">Read More <i
                          className="fas fa-long-arrow-alt-right"></i></Link>

                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-1 align-self-center">
                    <div className="port_blog_imgbox">
                      <img src="/images/blogs/web-development-thumbnail.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>


              <div className="port_blog_mainbox right_content">
                <div className="row no-gutters">
                  <div className="col-md-6 align-self-center">
                    <div className="port_blog_imgbox">
                      <img src="/images/blogs/e-health-thumbnail.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="port_blog_contentbox">
                      <span className="date_span">April 28, 2020</span>
                      <h4 className="blog_heading">
                        <Link to="/blog/5-ways-technology-is-improving-health">5 Ways Technology is Improving Health</Link>
                      </h4>
                      <div className="blog_info">
                        <ul>
                          <li><i className="fas fa-user-edit"></i><a href="# ">By Neo</a> </li>
                        </ul>
                      </div>
                      <p>It could be argued that of all the ways technology has improved the lives of humans across the globe, none are as important as the technological advances in medicine.</p>
                      <div className="blog_readmore">
                        <Link to="/blog/5-ways-technology-is-improving-health" className="readmore_btn">Read More <i
                          className="fas fa-long-arrow-alt-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* <div className="col-lg-8 col-md-12 ">
              <div className="port_blog_mainbox right_content">
                <div className="row no-gutters">
                  <div className="col-md-6 align-self-center">
                    <div className="port_blog_imgbox">
                      <img src="/images/blogs/e-health-thumbnail.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="port_blog_contentbox">
                      <span className="date_span">April 28, 2020</span>
                      <h4 className="blog_heading">
                        <Link to="/blog/5-ways-technology-is-improving-health">5 Ways Technology is Improving Health</Link>
                      </h4>
                      <div className="blog_info">
                        <ul>
                          <li><i className="fas fa-user-edit"></i><a href="# ">By Neo</a> </li>
                        </ul>
                      </div>
                      <p>It could be argued that of all the ways technology has improved the lives of humans across the globe, none are as important as the technological advances in medicine.</p>
                      <div className="blog_readmore">
                        <Link to="/blog/5-ways-technology-is-improving-health" className="readmore_btn">Read More <i
                          className="fas fa-long-arrow-alt-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="port_blog_mainbox left_content">
                <div className="row no-gutters">
                  <div className="col-md-6 order-md-1 order-2">
                    <div className="port_blog_contentbox">
                      <span className="date_span">February 10, 2020</span>
                      <h4 className="blog_heading">
                        <Link to="/blog/technology-stack-for-web-application-development-in-2020">Technology stack for web application development</Link>
                      </h4>
                      <div className="blog_info">
                        <ul>
                          <li><i className="fas fa-user-edit"></i><a href="# ">By Neo</a> </li>
                        </ul>
                      </div>
                      <p>What technologies are still on the top in 2020? Let’s see what tools are the best to work with:</p>
                      <div className="blog_readmore">
                        <Link to="/blog/technology-stack-for-web-application-development-in-2020" className="readmore_btn">Read More <i
                          className="fas fa-long-arrow-alt-right"></i></Link>

                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-1 align-self-center">
                    <div className="port_blog_imgbox">
                      <img src="/images/blogs/web-development-thumbnail.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>

            </div> */}

            <div className="col-md-12 col-lg-4">
              <div className="port_blog_mainbox vertical_content">
                <div className="port_blog_imgbox">
                  <img src="/images/blogs/ml-tech-thumbnail.png" alt="" className="img-fluid" />
                </div>


                <div className="port_blog_contentbox">
                  <span className="date_span">January 28, 2020</span>
                  <h4 className="blog_heading">
                    <Link to="/blog/the-6-most-important-technologies-in-machine-learning">
                      The 6 Most Important Technologies in Machine Learning
                    </Link>
                  </h4>
                  <div className="blog_info">
                    <ul>
                      <li><i className="fas fa-user-edit"></i><a href="# ">By Neo</a> </li>
                      {/* <li><i className="far fa-comments"></i><a href="# ">50</a></li> */}
                    </ul>
                  </div>
                  <p>With the sudden technological boom within the IT and development organisations a couple of years ago, both Artificial Intelligence (AI) </p>
                  <div className="blog_readmore">
                    <Link to="/blog/the-6-most-important-technologies-in-machine-learning" className="readmore_btn">Read More <i className="fas fa-long-arrow-alt-right"></i></Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogSection;
