import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const prevLocationRef = useRef();

  useEffect(() => {
    const prevLocation = prevLocationRef.current;

    if (location !== prevLocation) {
      const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, c - c / 10);
        }
      };
      scrollToTop();
    }

    // Store the current location for the next render
    prevLocationRef.current = location;
  }, [location]);

  return children;
}

export default ScrollToTop;