import React from 'react';

const SkillSet = () => {
  return (
    <div className="port_services_setions prt_toppadder80" data-scroll="1" id="skills_sec">
      <div className="services_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="port_heading_wrapper text-center prt_bottompadder40">
                <div className="port_sub_heading_wrapper">
                  <h2 className="port_sub_heading" >Take a look</h2>
                </div>
                <h1 className="port_heading">My Skill Set</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box ">
                  <img src="/images/python.png" style={{ width: 150 }} alt="" />
                  <h2 className="project_heading">Python</h2>
                  {/* <p className="project_pera">Vestibulum condimentum vehicula massa, ac convallis nullarell.</p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/django-logo.png" style={{ width: 150, marginBottom: -5 }} alt="" />
                  <h2 className="project_heading">Django Framework</h2>
                  <p className="project_pera">As a backend framework</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <div className="react-logo-div m-auto">
                    <img src="/images/react.gif" width="150px" style={{ marginTop: -17 }} alt="" />
                  </div>
                  <h2 className="project_heading">React JS</h2>
                  <p className="project_pera">As a frontend library</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/redux.jpg" width="150" alt="" />
                  <h2 className="project_heading">Redux</h2>
                  <p className="project_pera">For state management</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/docker-logo-1.png" width="150" style={{ marginTop: 10 }} alt="" />
                  <h2 className="project_heading">Docker</h2>
                  <p className="project_pera">To build containerized apps</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/postgresql1.png" className="invert" width={150} alt="" />
                  <h2 className="project_heading">
                    PostgreSQL
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/fastapi-logo.png" width="170" alt="" />
                  <h2 className="project_heading">FastAPI</h2>
                  <p className="project_pera">A modern web framework</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/terraform-logo-1.png" width="200" className="invert" alt="" />
                  <h2 className="project_heading" style={{ marginTop: -12 }}>Terraform</h2>
                  <p className="project_pera">Infrastructure as code</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/aws-logo.png" width="150" className="invert" alt="" />
                  <h2 className="project_heading" style={{ marginTop: -12 }}>Amazon Web Services</h2>
                  <p className="project_pera">Cloud Infrastructure</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/kubernetes-logo.png" width="200" className="invert" alt="" />
                  <h2 className="project_heading" style={{ marginTop: -12 }}>Kubernetes</h2>
                  <p className="project_pera">Container orchestration tool</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/chatgpt-logo.png" width="200" alt="" />
                  <h2 className="project_heading" style={{ marginTop: -12 }}>Apps using ChatGPT</h2>
                  <p className="project_pera">Building apps using OpenAI APIs</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="port_services_box_wrapper">
                <div className="port_services_box">
                  <img src="/images/services/port_services06.png" alt="" />
                  <h2 className="project_heading">More on my CV</h2>
                  {/* <p className="project_pera">Vestibulum condimentum vehicula massa, ac convallis nullarell.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkillSet;
