import React, { Component } from 'react'

class Education extends Component {
  render() {
    return (
      <div className="port_education_setions prt_toppadder80 prt_bottompadder80 " >
        <div className="education_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="port_heading_wrapper text-center prt_bottompadder40">
                  <div className="port_sub_heading_wrapper">
                    <h2 className="port_sub_heading" >Learning </h2>
                  </div>
                  <h1 className="port_heading">My Education</h1>
                </div>
              </div>
              <div className="col-md-12">
                <div className="education_main_wrapper ">
                  {/* <!-- first box --> */}
                  <div className="education_box education_firsrtbox firstbox">
                    <div className="row">
                      <div className="col-lg-6 col-md-4 col-sm-12 col-12 align-self-center">
                        <div className="education_mleft education_left ">
                          <div className="edu_mainyear edu_leftyear">
                            <h1>2014</h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-8 col-sm-12 col-12 align-self-center">
                        <div className="education_mright education_right ">
                          <div className="education_minfo education_rinfo ">
                            <div className="prt_rightside_title">
                              <div className="left_title_box">
                                <div className="left_title">
                                  <h4>01</h4>
                                </div>
                                <div className="right_title bg-pink">
                                  <h4>Tamil Nadu, India</h4>
                                </div>
                              </div>
                            </div>
                            <h3 className="education_place"><span data-hover="Diploma" className="data_hover">Bachelor </span> of Engineering</h3>
                            <p>
                              Completed Graduation from ANNA University in Electrical and Electronics Engineering.
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- first box --> */}

                  {/* <!-- second box --> */}
                  <div className="education_box education_secondbox fourthbox">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12 col-12 align-self-center">
                        <div className="education_mright education_left ">
                          <div className="education_minfo education_rinfo ">
                            <div className="prt_rightside_title">
                              <div className="left_title_box">
                                <div className="right_title bg-cyan">
                                  <h4>Manipur, India</h4>
                                </div>
                                <div className="left_title">
                                  <h4>02</h4>
                                </div>
                              </div>
                            </div>
                            <h3 className="education_place"><span data-hover="Diploma" className="data_hover">Higher Secondary </span></h3>
                            <p>
                              Completed Higher Secondary from Herbert School, Imphal in Science stream.
                            </p>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-12 col-12 align-self-center">
                        <div className="education_mleft education_left ">
                          <div className="edu_mainyear edu_leftyear">
                            <h1>2010</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- second box --> */}

                  {/* <!-- third box --> */}
                  <div className="education_box education_firsrtbox thirdbox">
                    <div className="row">
                      <div className="col-lg-6 col-md-4 col-sm-12 col-12 align-self-center">
                        <div className="education_mleft education_left ">
                          <div className="edu_mainyear edu_leftyear">
                            <h1>2008</h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-8 col-sm-12 col-12 align-self-center">
                        <div className="education_mright education_right ">
                          <div className="education_minfo education_rinfo ">
                            <div className="prt_rightside_title">
                              <div className="left_title_box">
                                <div className="left_title">
                                  <h4>03</h4>
                                </div>
                                <div className="right_title bg-orange">
                                  <h4>Manipur, India</h4>
                                </div>
                              </div>
                            </div>
                            <h3 className="education_place"><span data-hover="Diploma" className="data_hover">Higher Secondary </span></h3>
                            <p>
                              Completed High School from BOSEM, Manipur in 2008
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- third box --> */}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Education
