import React from 'react';

const ExperienceSection = () => {
  return (
    <div className="port_experience_setions prt_toppadder80">
      <div className="experience_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="port_heading_wrapper text-center prt_bottompadder40">
                <div className="port_sub_heading_wrapper">
                  <h2 className="port_sub_heading" >Involvement</h2>
                </div>
                <h1 className="port_heading">My Experience</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="exprince_box ex_leftsidebox">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                    <div className="ex_leftside">
                      <h1>2015</h1>
                      <h4>Jan to July</h4>
                      <h1>2015</h1>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                    <div className="ex_rightside">
                      <h4>DataStage ETL <span data-hover="Designer" className="data_hover c-pink">Developer</span></h4>
                      <span className="c-pink">Accenture</span>
                      <p className="ex_details">
                        Developed ETL Modules to process huge volume of data generated from business

                      </p>
                      <p className="more_content d-block" >and stored to DB for reporting.</p>
                      {/* <a className="ex_btn">Read More</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="exprince_box ex_leftsidebox">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                    <div className="ex_leftside">
                      <h1>2015</h1>
                      <h4>Jan to July</h4>
                      <h1>2015</h1>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                    <div className="ex_rightside">
                      <h4>SQL/Database <span data-hover="Designer" className="data_hover c-yellow">Developer</span></h4>
                      <span className="c-yellow">Accenture</span>
                      <p className="ex_details">
                        Designed and developed tables, view, store procedures, DB Scripts. These go along with
                      </p>
                      <p className="more_content d-block">ETL to store data
                        and for reporting.</p>
                      {/* <a href="# " className="ex_btn">Read More</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="exprince_box ex_rightsidebox">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                    <div className="ex_rightside">
                      <h4>Django <span data-hover="Developer" className="data_hover c-orange">Developer</span></h4>
                      <span className="c-orange">Accenture</span>
                      <p className="ex_details">
                        Developed backend code for client apps through Django, Django-Rest-Framework
                      </p>
                      <p className="more_content d-block">as REST API.</p>
                      {/* <a href="# " className="ex_btn">Read More</a> */}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                    <div className="ex_leftside">
                      <h1>2015</h1>
                      <h4>Jul to Jul</h4>
                      <h1>2018</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="exprince_box ex_rightsidebox">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                    <div className="ex_rightside">
                      <h4>React JS <span data-hover="Developer" className="data_hover c-cyan">Developer</span></h4>
                      <span className="data_hover c-cyan">Accenture</span>
                      <p className="ex_details">
                        Designed and developed frontend apps in React JS and Redux library with Django as backend
                      </p>
                      <p className="more_content d-block">web framework</p>
                      {/* <a href="# " className="ex_btn">Read More</a> */}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                    <div className="ex_leftside">
                      <h1>2015</h1>
                      <h4>Jul to Jul</h4>
                      <h1>2018</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="exprince_box ex_leftsidebox">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                    <div className="ex_leftside">
                      <h1>2018</h1>
                      <h4>Sept to Nov</h4>
                      <h1>2020</h1>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                    <div className="ex_rightside">
                      <h4>Full Stack <span data-hover="Designer" className="data_hover c-yellow">Developer</span></h4>
                      <span className="c-yellow">Freelance</span>
                      <p className="ex_details">Designed and developed complex webapps for clients through freelancing</p>
                      <p className="more_content">Extra contant Sed ut perspiciatis unde omnis iste natus.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="exprince_box ex_leftsidebox">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                    <div className="ex_leftside">
                      <h1>2021</h1>
                      <h4>Feb to Sept</h4>
                      <h1>2022</h1>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                    <div className="ex_rightside">
                      <h4>Senior Software <span data-hover="Designer" className="data_hover c-green">Engineer</span></h4>
                      <span className="c-green">Reckonsys Tech Labs</span>
                      <p className="ex_details">Worked on Microservices, Distributed systems, Docker, Backend APIs, AWS Services, Celery</p>
                      <p>Terraform, Copilot</p>

                      {/* <p className="more_content">Extra contant Sed ut perspiciatis unde omnis iste natus.</p> */}
                      {/* <a href="# " className="ex_btn">Read More</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="exprince_box ex_rightsidebox">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                    <div className="ex_rightside">
                      <h4>Senior Software <span data-hover="Developer" className="data_hover c-purple">Engineer III</span></h4>
                      <span className="c-purple">Zemoso Labs</span>
                      <p className="ex_details">
                        Working as a Full Stack developer currently. Python, FastAPI, Mircroservices, React
                      </p>
                      <p>AWS, SQLAlchemy, Terraform</p>
                      {/* <a href="# " className="ex_btn">Read More</a> */}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                    <div className="ex_leftside">
                      <h1>2022</h1>
                      <h4>Oct to</h4>
                      <h1>Present</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExperienceSection;
