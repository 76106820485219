import React, { useState } from 'react';
import BlogSidebar from './BlogSidebar';
import PageLoader from '../PageLoader';

const SixTechsInMachingeLearning = () => {
  document.getElementById("top_banner").classList.add("d-none");
  const [imageLoading, setImageLoading] = useState(true);

  const handleImageLoaded = () => {
    setImageLoading(false);
  }

  const loading = imageLoading ? <PageLoader /> : "";

  return (
    <div className="port_singleblog_wrapper prt_toppadder80 prt_bottompadder80 ">
      {loading}
      <div className={`container ${imageLoading ? 'invisible' : ''}`}>
        <div className="row">
          <div className="col-lg-8">
            <div className="blog_wrapper">
              <div className="blog_data">
                <div className="blog_thumb">
                  <img id="blogImage" src="/images/blogs/ml-tech.png" onLoad={handleImageLoaded} alt="" className="img-fluid" />
                </div>
                <div className="blog_content">
                  <div className="blog_postinfo pb-3">
                    <ul>
                      <li><a href=" #"> <i className="fas fa-calendar-alt"></i> january 28th, 2020</a></li>
                      <li><a href=" #"> <i className="fas fa-user-edit"></i> by Neo</a></li>
                    </ul>
                  </div>
                  <h4 className="blog_heading mb-2">The 6 Most Important Technologies in Machine Learning</h4>

                  <p>With the sudden technological boom within the IT and development organisations a couple of years ago, both Artificial Intelligence (AI) and Machine Learning have now become the trending careers for a lot of people to follow. With so many businesses coming up and clamouring for the best new talent, today, the industry is experiencing a shortage of skilled and qualified professionals. However, a plethora of tech professionals have rushed to fill in this gap by learning all of the technologies associated with machines learning and AI and adding them to their skillset.</p>
                  <p>Since this is mainly limited to key learning languages and does not break new ground, most people in these industries are now beginning to realise the importance of looking beyond the learning languages as these will decide the future. There is no simple solution as to which technology to watch out for as things are in a constant state of flux and all the new and old frameworks are constantly evolving.</p>
                  <p>However, since it has been established that AI is rapidly transforming every sphere of our life (think Siri and the like), there are certain key AI technologies to focus on so that one can take machine learning projects to the next level. Here is an informative list of the six best technologies one can use:-</p>
                  <p><strong>&bull;&nbsp; Keras:</strong>&nbsp;This is an open source software library that focuses on simplifying the creation of deep&nbsp;<a href="http://firstclasseducation.org/online-courses/a-definitive-guide.php">learning models</a>. Written in Python, it can also be deployed on top of many other AI technologies such as Theano and TensorFlow. It runs optimally on both CPUs and GPUs, plus it is known for its user-friendliness as well as fast prototyping.</p>
                  <p><strong>&bull;&nbsp; Torch:</strong>&nbsp;One of the oldest such technologies released all the way back in 2002, it is a machine learning library that has a variety of algorithms to offer for deep learning. With an open source framework, you will be provided with the best speed and flexibility without having to worry about any complexities getting in the way.</p>
                  <p><strong>&bull;&nbsp; Caffe:</strong>&nbsp;Being one of the more recent options, the best part about Caffe is that it inspires a degree of innovation with an expressive architecture along with the provision of a vibrant community. This machine learning framework primarily focuses on speed, expressiveness and modularity.&nbsp;</p>
                  <p><strong>&bull;&nbsp; TensorFlow:</strong>&nbsp;With the initial release of this open source machine learning framework being 2015, it has been deployed across many different platforms and is easy to use. Created by Google at first, now all the top tech giants such as eBay, Dropbox, Intel and Uber use it extensively. With the help of flowgraphs, one can develop neural networks.</p>
                  <p><strong>&bull;&nbsp; Theano:</strong>&nbsp;This is basically an open source Python library that you can use to fashion various machine learning models. Being one of the oldest libraries, it is regarded as an Industry standard. It simplifies the process of optimising, defining and assessing mathematical expressions.</p>
                  <p><strong>&bull;&nbsp; Microsoft Cognitive Toolkit:</strong>&nbsp;Initially released about three years back, this is an AI solution that you can use to take your machine learning projects to the next level in every way. Certain studies have revealed that the open source framework can train certain algorithms to function like the human brain.</p>
                  <p>One has to take note of the fact that building a machine learning application is one thing, but selecting the ideal technology from the many options out there is another ball game altogether. It is anything but a simple task to achieve and evaluating many different options before selecting the final one is a must.</p>
                  <p>Furthermore, learning how the various&nbsp;<a href="https://elearningindustry.com/directory/software-categories/learning-management-systems">machine learning technologies</a>&nbsp;work separately and with each other will be a key component of your decision-making process in totality. Most importantly, it will also play a decisive role in ensuring that you stay ahead of the pack with regard to your contemporaries.</p>


                  <div className="blog_shareinfo">
                    <div className="blog_tag">
                      <a href=" #"> <i className="fas fa-tags"></i> education, Software, portfolio</a>
                    </div>
                    <div className="blog_social">
                      <ul className="social_list">
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-facebook-f nav_fb"></i></span>
                            <span className="second_icon"><i className="fab fa-facebook-f nav_fb"></i></span>
                          </a>
                        </li>
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-linkedin-in nav_in"></i></span>
                            <span className="second_icon"><i className="fab fa-linkedin-in nav_in"></i></span>
                          </a>
                        </li>
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-whatsapp nav_whats"></i></span>
                            <span className="second_icon"><i className="fab fa-whatsapp nav_whats"></i></span>
                          </a>
                        </li>
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-twitter nav_twit"></i></span>
                            <span className="second_icon"><i className="fab fa-twitter nav_twit"></i></span>
                          </a>
                        </li>
                        <li>
                          <a href=" #" className="siderbar_icon">
                            <span className="first_icon"><i className="fab fa-instagram nav_insta"></i></span>
                            <span className="second_icon"><i className="fab fa-instagram nav_insta"></i></span>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <BlogSidebar />
        </div>
      </div>
    </div>
  );
}

export default SixTechsInMachingeLearning;
