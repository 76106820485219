import React from 'react'
import { BrowserRouter as Router, Route, Routes as RouteSwitch } from 'react-router-dom';
import Home from './pages/Home';

import ScrollToTop from './utility/ScrollToTop';

import SixTechsInMachingeLearning from './components/blogs/SixTechsInMachingeLearning';
import WebDevTechStacks from './components/blogs/WebDevTechStacks';
import TechnologyImprovingHealth from './components/blogs/TechnologyImprovingHealth';
import ChatGPTApplications from './components/blogs/ChatGPTApplications';

const App = () => {
  return (
    <Router>
      <ScrollToTop>

        <div className="port_sec_warapper">
          <RouteSwitch>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/blog/the-6-most-important-technologies-in-machine-learning" element={<SixTechsInMachingeLearning />} />
            <Route exact path="/blog/technology-stack-for-web-application-development-in-2020" element={<WebDevTechStacks />} />
            <Route exact path="/blog/5-ways-technology-is-improving-health" element={<TechnologyImprovingHealth />} />
            <Route exact path="/blog/applications-of-chatgpt" element={<ChatGPTApplications />} />
          </RouteSwitch>
        </div>
      </ScrollToTop>
    </Router>

  )
}

export default App;
